import Work1 from "../../assets/work1.png";
import Work2 from "../../assets/work2.png";
import Work3 from "../../assets/work3.png";
import Work4 from "../../assets/work4.png";
import Work5 from "../../assets/work5.png";
import Work6 from "../../assets/work6.png";
import Work7 from "../../assets/work7.png";
import Work8 from "../../assets/work8.png";
import Work9 from "../../assets/work9.png";
import Work10 from "../../assets/work10.png";
import Work11 from "../../assets/work11.png";
import Work12 from "../../assets/work12.png";
import Work13 from "../../assets/work13.png";
import Work14 from "../../assets/work14.png";



export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Falando Serio",
    category: "New Releases",
    demoLink: "https://www.discogs.com/master/579984-Apoena-Falando-Serio",
    description:
      "Deep House, Tech House - 2010",
  },
  {
    id: 2,
    image: Work2,
    title: "Mover & Cooler",
    category: "Singles",
    demoLink: "https://www.discogs.com/release/3459231-Apoena-Mover-Cooler",
    description:
      "Techno, Deep House - 2012",
  },

  {
    id: 3,
    image: Work3,
    title: "Dourada (Rmxs)",
    category: "Collaborations",
    demoLink: "https://www.discogs.com/release/4552657-Apoena-51-Dourada-Rmxs",
    description:
      "House - 2013",
  },
  {
    id: 4,
    image: Work4,
    title: "Inner Monsters EP",
    category: "Collaborations",
    demoLink: "https://soundcloud.com/apoena51/casanova-inner-monsters-ep-12",
    description:
      " Techno, Deep House - 2016",
  },
  {
    id: 5,
    image: Work5,
    title: "Allnite Music 003",
    category: "Collaborations",
    demoLink: "https://soundcloud.com/allnite-music/sets/allnite-music-003-vinyl-only-va",
    description:
      "Techno, Deep House - 2018",
    },
    {
      id: 6,
      image: Work6,
      title: "Alex Agore / Apoena",
      category: "Singles",
      demoLink: "https://www.discogs.com/release/6518243-Alex-Agore-Apoena-Basement-Jam",
      description:
        "Deep House, House, Nu-Disco - 2015",
  },
  {
    id: 7,
    image: Work7,
    title: "Intense EP",
    category: "Singles",
    demoLink: "https://www.discogs.com/release/8429393-Apoena-Intense-EP",
    description: "Deep House - 2016",
  },
  {
    id: 8,
    image: Work8,
    title: "Lost EP",
    category: "Releases",
    demoLink: "https://www.discogs.com/release/9951975-Apoena-Lost-EP",
    description: "Deep House - 2017",
  },
  {
    id: 9,
    image: Work9,
    title: "Nebulosa EP",
    category: "New Releases",
    demoLink: "https://www.discogs.com/release/12735145-Apoena-Nebulosa-EP",
    description: "Deep House, Dub Techno - 2018",
  },
  {
    id: 10,
    image: Work10,
    title: "Edits Brazuca",
    category: "New Releases",
    demoLink: "https://www.discogs.com/release/11366265-Apoena-Edits-Brazuca",
    description: "Deep House - 2020",
  },
  {
    id: 11,
    image: Work14,
    title: "Retoside EP",
    category: "New Releases",
    demoLink: "https://www.discogs.com/release/13860121-Apoena-Retoside-EP",
    description: "Deep House - 2019",
  },
  {
    id: 12,
    image: Work12,
    title: "Soul People EP",
    category: "New Releases",
    demoLink: "https://www.discogs.com/release/25451701-Apoena-Soul-People-EP",
    description: "Deep House - 2022",
  },
  {
    id: 13,
    image: Work13,
    title: "Oceanos Extintos EP",
    category: "New Releases",
    demoLink: "https://www.discogs.com/release/24734807-Apoena-Oceanos-Extintos-EP",
    description: "Deep House, Dub Techno - 2022",
  },
  {
    id: 14,
    image: Work11,
    title: "Mystic EP",
    category: "New Releases",
    demoLink: "https://www.discogs.com/release/29941441-Apoena-Mystic-EP",
    description: "Deep House, Disco - 2018",
  },
  
];

export const projectsNav = [
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
  {
    name: "",
  },
];

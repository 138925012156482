import React from 'react';
import Project from './Project';
import './projects.css';
import label from '../../assets/img_label.png';

import FrontEnd from '../skills/FrontEnd';



const Projects = () => {
  return (
    <section style={{marginTop:"50px"}} className="work_section" id='#portfolio'>
        <h2  className="section__title2">Selected Discography</h2>
        

        <Project />
        <div  style={{marginTop:30}}>
    <img  src={label} alt="label" className="apoena" />
     </div>
     
    </section>

   


  )
}

export default Projects
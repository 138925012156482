import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title" style={{textAlign:"center"}}>Apoena</h1>

        <ul className="footer__list">
        {/* <li>
  <a href="https://on.soundcloud.com/xt5HL" class="footer__link">
    Listen Set
  </a>
</li> */}
          <li>
            <a href="#portfolio" className="footer__link">
              Discography
            </a>
          </li>
         
        </ul>

        <div className="footer__social">
          <a
            href="https://www.facebook.com/henrique.casanova?rdid=5qFQyb0fVQRJ5Vjm&share_url=https%3A%2F%2F"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-facebook"></i>
          </a>

          <a
            href="https://www.instagram.com/apoena_/?igsh=MTlldjg4aXRha2FqbQ%3D%3D"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-instagram"></i>
          </a>
          <a
            href="https://open.spotify.com/artist/7BZXGUM5lLUZsKrCLeIpsK"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-spotify"></i>
          </a>
         

          <a
            href="https://soundcloud.com/apoena51"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-soundcloud"></i>
          </a>
        </div>

        <span className="footer__copy">
            &#169; Apoena. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;

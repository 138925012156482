import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_FORM_ID
      )
      .then(
        function(response) {
          console.log("Email sent successfully:", response);
        },
        function(error) {
          console.error("Error sending email:", error);
        }
      );

    e.target.reset();
  };

  return (

    
    <section className="contact section" id="contact">
      <h2 className="section__title3">Contact</h2>
      

      <div className="contact__container container grid">
        <div className="contact__content">
          <h3 className="contact__title">***bookings***</h3>

          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__card-icon"></i>

              <h3 className="contact__card-title">E-Mail</h3>
              <span className="contact__card-data">henriquecasanova@hotmail.com</span>

              <a
                href="mailto:henriquecasanova@hotmail.com"
                className="contact__button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact via E-Mail
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-whatsapp contact__card-icon"></i>

              <h3 className="contact__card-title">WhatsApp</h3>
              <span className="contact__card-data">+49 176 58022422</span>

              <a
                href="https://wa.me/4917658022422?text=Hello%Apoena!"
                className="contact__button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact via WhatsApp
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>

        
      </div>
    </section>
  );
};

export default Contact;

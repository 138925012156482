import React from 'react'

const Data = () => {
  return (
    <div className="home__data">
        <h1 className="home__title">APOENA

 


        </h1>

        <h3 className="home__subtitle">
            (Freerange; King Street; Hudd Traxx; 
            <p>Allnite Music and more)</p>
        </h3>
        <div className='home__description_container'>
        <p style={{color:"black"}} className="home__description">
        <b>APOENA</b> is a House Music artist from <b>Brazil</b>. His influences
on the Detroit sound and the late 90's makes his music old
school and misterious, sometimes flirting with Techno. His
tracks are played by veteran DJs as Derrick May, Laurent
Garnier, Delano Smith, Luke Hess, just to name a few, attesting
the classic caracteristic of his music. <p style={{display:'inline'}}><b>APOENA</b> have releases
out in labels like <b>Freerange</b>, <b>Nite Grooves</b>, <b>Hudd Traxx</b>, <b>Underground Quality</b> and his own <b>Allnite Music</b>. The brazilian
producer have 25 years DJ experience in his home country,
playing important stages there like D-Edge, Colours, Levels
and running his own parties. Recently moved to Berlin, <b>APOENA</b> had allready played in clubs like <b>Sisyphos</b>, <b>Kitkat</b>, <b>Golden Gate</b>, <b>Anomalie</b>, <b>Renate</b>, <b>Oxi</b> and more.</p></p>
        </div>


        <div className="btn">
        <a href="#contact" className="button button--flex">Bookings
        <svg
                  className="button__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                    fill="var(--container-color)"
                  ></path>
                  <path
                    d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                    fill="var(--container-color)"
                  ></path>
        </svg>
        </a>
        <a href="https://soundcloud.com/apoena51/apoena-sisyphos-wintergarten-28082022?ref=clipboard&p=a&c=1&si=befdd50598444a3ba309143193ff29f2&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="button button--flex">Listen Set
        <svg
                  className="button__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                    fill="var(--container-color)"
                  ></path>
                  <path
                    d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                    fill="var(--container-color)"
                  ></path>
        </svg>
        </a>
        </div>
    </div>
  )
}

export default Data
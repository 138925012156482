import './skills.css';
import React from 'react'
import apoenavideo1 from '../../assets/apoenavideo1.png';
import apoenavideo2 from '../../assets/apoenavideo2.png';



const Skills = () => {
  return (
    
      
        
        
        
  
      
        
        

        <div className="">
        <h2 id='skills' className='section__title'> News</h2>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    
                    
                <div className='news'>
    <a href="https://levisiteuronline.com/music/lv-selectors-47-apoena-freerange/">
        <h3 className="skills__name">Deep House Mix + Interview</h3>
        <span className="skills__level">(Le Visiteur)</span>
    </a>
</div>
                </div>

                <div className="skills__data">
                    
                <div className="skills__data">
    <a href="https://www.musicis4lovers.com/premiere-interview-apoena-mind-freerange-records/">
        <h3 className="skills__name">Interview on the occasion of Dark Emerald EP</h3>
        <span className="skills__level">(Music Is 4 Lovers)</span>
    </a>
</div>
                
               
                </div>
                
                    
                <div className="skills__data img_video">
    <a href="https://www.youtube.com/watch?v=ZUNBBOUtbZI&t=2s&ab_channel=H%C3%96RBERLIN">
    <h3 className="skills__name">Video Set @ HOR
    </h3>
        
    <img  className='apoena' src={apoenavideo1} alt="gigs" />
        <span className="skills__level">(HOR Youtube)</span>

    </a>
</div>
                <div className="skills__data img_video">
    <a href="https://www.youtube.com/watch?v=B-OOvpM8XaU&ab_channel=APOENA-AllniteMusic">
    <h3 className="skills__name">Video Set @ Kitkat Club - Berlin</h3>
    <img  className='apoena' src={apoenavideo2} alt="gigs" />
        <span className="skills__level">(Kitkat TV Youtube)</span>

    </a>
</div>
                
               
                </div>

               
            </div>
        </div>
    
  )
}

export default Skills;